import loginMixin from "@/js/pages/login";
import {
  // 通过手机验证码修改密码
  changePswByCode,
  // 通过邮箱验证码修改密码
  changePswByEmailCode,
  // 手机号注册账号
  registerByPhone,
  // 邮箱注册账号
  registerByEmail,
  //获取当前进入网站用户的ip地址
  getips,
  // 验证手机号验证码是否有效
  registerconfirmed_tel_code,
  // 验证邮箱验证码是否有效
  registerconfirmed_email_code,
  // 检测用户名唯一性
  registerwrite,
  wechatis_subscribe_wechat_official_account,
  wechatget_auth_information


} from "@/api/api";
import {
  catchErr
} from "@/js/validate";

export default {
  name: "register",
  mixins: [loginMixin],
  data() {
    return {
      showIterm: false,
      checked: false,
      visible: false,
      registerType: 'email',
      // 步骤条
      step: 1,
      // 用户所属
      address: 'zh_CN',
      //用户名登入时的form
      ruleForm: {
        names: "",
        psw: "",
        confirmPsw: "",
        phone: "",
        code: "",
        email: "",
        codes: ''
      },
    };
  },
  mounted() {
    getips({

    }).then((res) => {
      // console.log(res, 987);
      if (res.data[0].country.iso_code == 'CN') {
        this.address = 'zh_CN'
        // i18n.global.locale = 'zh_CN'
      } else if (res.data[0].country.iso_code == '未知') {
        // i18n.global.locale = 'zh_CN'
        this.address = 'zh_CN'
      } else {
        // i18n.global.locale = 'en_US'
        this.address = 'en'
      }
    })

    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    if (step == 4) {
      const cachedRuleForm = localStorage.getItem('ruleForm');
      // console.log(123);
      this.step = 4
      if (cachedRuleForm) {
        const cachedData = JSON.parse(cachedRuleForm);
        // 恢复缓存的数据
        this.ruleForm = cachedData || this.ruleForm;
        wechatget_auth_information({
          tag_email: this.ruleForm.email,
        }).then((res) => {
          if (res.success) {
            this.wxauthorization = res.message
            this.visible = false
            // clearInterval(refreshRequest);
            // this.afterGotToken(res.token);
          } else {
            this.$msg(res.message);
          }
        })

        // 根据需要恢复其他表单字段
      }
    }








  },
  methods: {
    getusername(e) {
      // console.log(this.ruleForm.name, 123456);
      // registerdetect_username({
      //   username: this.ruleForm.name
      // }).then((res) => {
      //   // console.log(res, 123);
      // }).catch((err) => {
      //   // if()
      //   // console.log(err, 123);
      //   // this.usernameErr = err.errors.username[0]
      //   // this.$refs["ruleForm"].clearValidate();
      //   // this.$refs["mRuleForm"].clearValidate();
      // })
    },
    clickWxLogo() {
      this.visible = !this.visible;
      setTimeout(() => {
        this.$refs.dcPopover.updatePopper();
      }, 210);
    },
    skipstep() {
      this.step = 3
    },
    tologin3() {
      // console.log(123);
      // this.address
      registerwrite({
        username: this.ruleForm.names,
        password: this.ruleForm.psw,
        password_confirmation: this.ruleForm.psw,
        tel: this.ruleForm.phone,
        email: this.ruleForm.email,
        lang: this.address
      }).then((res) => {
        if (res.token) {
          setTimeout(() => {
            this.afterGotToken(res.token);
          }, 500);
        } else {
          this.$msg(res.message);
        }
      }).catch((err) => {
        this.$msg(err.message);
      })
    },
    tologin() {
      if (this.wxauthorization) {
        this.step = 5
      } else {
        this.$msg('请您在微信授权后再进行下一步操作');
      }
    },
    tologins(e) {
      if (e == 0) {
        this.step = 3
      } else {
        wechatis_subscribe_wechat_official_account({
          tag_email: this.ruleForm.email
        }).then((res) => {
          // console.log(res,1);
          if (res.success) {
            this.step = 3
          } else {
            this.$msg(res.message);
          }
        }).catch((err) => {
          // console.log(err,12);

        })
      }


    },

    //submit
    // 第二步下一步执行的方法
    submit3(name) {
      this.$refs[name].validate((valid) => {
        // console.log(valid, 98797);
        // return;
        if (valid) {
          // 当前未注册下执行的操作
          if (
            this.$route.path === "/register" ||
            this.$route.path === "/mRegister"
          ) {
            console.log(this.ruleForm.email, this.ruleForm.phone, this.ruleForm.psw, this.ruleForm.names);
            // 未同意所有注册条款
            // if (this.checked === false) {
            //   console.log(1)
            //   this.$msg(this.$t(ctc));
            //   return false;
            // }
            // 当前为手机号注册   注册的逻辑
            if (this.registerType == 'phone') {

              registerconfirmed_email_code({
                email: this.ruleForm.email,
                code: this.ruleForm.codes,
                lang: this.address
              }).then((res) => {
                console.log(res, '邮箱', 12355);
                this.step = 4
              }).catch((err) => {
                if (err.message == '非法请求') {
                  this.codeErr = err.errors.email
                }
                this.codeErr = err.message
                return;
                // console.log(err, '邮箱', 12355);
              })
              // console.log(2);

            } else if (this.registerType == 'email') {
              // console.log(3);
              registerconfirmed_tel_code({
                tel: this.ruleForm.phone,
                code: this.ruleForm.codes
              }).then((res) => {
                console.log(res, '手机号', 12355);
                this.step = 4
              }).catch((err) => {
                if (err.message == '非法请求') {
                  this.codeErr = err.errors.tel
                }
                this.codeErr = err.message
                return;
                // console.log(err, '手机号', 12355);
              })
            }

          }
          this.errReset();
        } else {
          return false;
        }
      });


    },

    // 第一步注册执行的方法
    submit2(name, uname) {
      // console.log(name,uname,98989);
      if (uname == 'v1') {
        this.$refs[name].validate((valid) => {
          console.log(valid, 98797);
          // return;
          if (valid) {
            // 当前未注册下执行的操作
            if (
              this.$route.path === "/register" ||
              this.$route.path === "/mRegister"
            ) {
              // 未同意所有注册条款
              if (this.checked === false) {
                console.log(1)
                this.$msg(this.$t('ctc'));
                return false;
              }
              // 当前为手机号注册   注册的逻辑
              if (this.registerType === "phone") {
                console.log(2)
                registerByPhone({
                    tel: this.ruleForm.phone,
                    username: this.ruleForm.name,
                    password: this.ruleForm.psw,
                    lang: localStorage.lang,
                    password_confirmation: this.ruleForm.psw,
                    code: this.ruleForm.code,
                    lang: localStorage.lang,
                  })
                  .then((res) => {
                    this.$msg(this.$t("sra"));
                    console.log(res)
                    setTimeout(() => {
                      this.afterGotToken(res.token);
                    }, 500);
                  })
                  .catch((err) => {
                    catchErr(err, this);
                  });
              } else if (this.registerType === "email") {
                // 当前为邮箱注册
                registerByEmail({
                    email: this.ruleForm.email,
                    username: this.ruleForm.name,
                    password: this.ruleForm.psw,
                    password_confirmation: this.ruleForm.psw,
                    code: this.ruleForm.code,
                    lang: localStorage.lang,
                  })
                  .then((res) => {
                    this.$msg(this.$t("sra"));
                    setTimeout(() => {
                      this.afterGotToken(res.token);
                    }, 500);
                  })
                  .catch((err) => {
                    catchErr(err, this);
                  });
              }
              // this.step = 2
            } else {
              // 通过email修改密码所执行的的操作
              if (this.registerType === "email") {
                changePswByEmailCode({
                    email: this.ruleForm.email,
                    password: this.ruleForm.psw,
                    code: this.ruleForm.code,
                    lang: localStorage.lang,
                  })
                  .then((res) => {
                    this.$msg(this.$t("psc"));
                    setTimeout(() => {
                      this.afterGotToken(res.token);
                    }, 500);
                  })
                  .catch((err) => {
                    catchErr(err, this);
                  });
              } else if (this.registerType === "phone") {
                // 通过phone修改密码所执行的操作
                changePswByCode({
                    tel: this.ruleForm.phone,
                    password: this.ruleForm.psw,
                    code: this.ruleForm.code,
                    lang: localStorage.lang,
                  })
                  .then((res) => {
                    this.$msg(this.$t("psc"));
                    setTimeout(() => {
                      this.afterGotToken(res.token);
                    }, 500);
                  })
                  .catch((err) => {
                    catchErr(err, this);
                  });
              }
            }
            this.errReset();
          } else {
            return false;
          }
        });
      } else {
        // console.log(123444444);
        this.$refs[name].validate((valid) => {
          // console.log(valid, 98797);
          // return;
          if (valid) {
            // 当前未注册下执行的操作
            if (
              this.$route.path === "/register" ||
              this.$route.path === "/mRegister"
            ) {
              // 未同意所有注册条款
              if (this.checked === false) {
                // console.log(1)
                this.$msg(this.$t('ctc'));
                return false;
              }
              // 注册账号
              // console.log();
              // console.log(this.ruleForm.phone,this.ruleForm.code,this.ruleForm);
              // return;
              // console.log(this.registerType);

              if (this.registerType == 'phone') {
                // console.log(2);
                registerconfirmed_tel_code({
                  tel: this.ruleForm.phone,
                  code: this.ruleForm.code
                }).then((res) => {
                  console.log(res, '手机号', 12355);
                  this.step = 2
                }).catch((err) => {
                  if (err.message == '非法请求') {
                    this.codeErr = err.errors.tel
                  }
                  this.codeErr = err.message
                  return;
                  // console.log(err, '手机号', 12355);
                })
              } else if (this.registerType == 'email') {
                // console.log(3);
                registerconfirmed_email_code({
                  email: this.ruleForm.email,
                  code: this.ruleForm.code,
                  lang: this.address
                }).then((res) => {
                  console.log(res, '邮箱', 12355);
                  this.step = 2
                }).catch((err) => {
                  if (err.message == '非法请求') {
                    this.codeErr = err.errors.email
                  }
                  this.codeErr = err.message
                  return;
                  // console.log(err, '邮箱', 12355);
                })
              }




              // 当前为手机号注册   注册的逻辑
              // if (this.registerType === "phone") {
              //   console.log(2)
              //   registerByPhone({
              //       tel: this.ruleForm.phone,
              //       username: this.ruleForm.name,
              //       password: this.ruleForm.psw,
              //       lang: localStorage.lang,
              //       password_confirmation: this.ruleForm.psw,
              //       code: this.ruleForm.code,
              //       lang: localStorage.lang,
              //     })
              //     .then((res) => {
              //       this.$msg(this.$t("sra"));
              //       console.log(res)
              //       setTimeout(() => {
              //         this.afterGotToken(res.token);
              //       }, 500);
              //     })
              //     .catch((err) => {
              //       catchErr(err, this);
              //     });
              // } else if (this.registerType === "email") {
              //   // 当前为邮箱注册
              //   registerByEmail({
              //       email: this.ruleForm.email,
              //       username: this.ruleForm.name,
              //       password: this.ruleForm.psw,
              //       password_confirmation: this.ruleForm.psw,
              //       code: this.ruleForm.code,
              //       lang: localStorage.lang,
              //     })
              //     .then((res) => {
              //       this.$msg(this.$t("sra"));
              //       setTimeout(() => {
              //         this.afterGotToken(res.token);
              //       }, 500);
              //     })
              //     .catch((err) => {
              //       catchErr(err, this);
              //     });
              // }

              // if (this.step = 2) {
              //   this.step = 3
              // }
            } else {
              // 通过email修改密码所执行的的操作
              if (this.registerType === "email") {
                changePswByEmailCode({
                    email: this.ruleForm.email,
                    password: this.ruleForm.psw,
                    code: this.ruleForm.code,
                    lang: localStorage.lang,
                  })
                  .then((res) => {
                    this.$msg(this.$t("psc"));
                    // setTimeout(() => {
                    //   this.afterGotToken(res.token);
                    // }, 500);
                  })
                  .catch((err) => {
                    catchErr(err, this);
                  });
              } else if (this.registerType === "phone") {
                // 通过phone修改密码所执行的操作
                changePswByCode({
                    tel: this.ruleForm.phone,
                    password: this.ruleForm.psw,
                    code: this.ruleForm.code,
                    lang: localStorage.lang,
                  })
                  .then((res) => {
                    this.$msg(this.$t("psc"));
                    setTimeout(() => {
                      this.afterGotToken(res.token);
                    }, 500);
                  })
                  .catch((err) => {
                    catchErr(err, this);
                  });
              }
            }
            this.errReset();
          } else {
            return false;
          }
        });
      }

    },
  },
  watch: {
    // visible(val) {
    //   console.log(123213213213);
    //   if (val === true) {
    //     // this.wxLogin();
    //     console.log(123);
    //   }
    // },
    // '$router.query': function (newUid) {
    //   // this.uid = newUid;
    //   // this.handleRouteChange();
    //   console.log(1234);
    // }

  },
};